<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('subMenu.guide') }}</h3>
      </div>
      <!--/title-sort-box-->

      <div class="accordion-box">
        <h4 class="acc-head">
          <span class="tit os01"
            ><b>{{ $t('guide.omron') }}</b
            >(SDK 1.7)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('android')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('android')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="omronFlag ? 'active' : ''"
            @click="omronFlag = !omronFlag"
          >
            {{ omronFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="omronFlag ? 'display:block' : ''"
          v-html="$t('guide.omron-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os01"
            ><b>{{ $t('guide.miband') }}</b
            >(SDK 1.7)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('android')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('android')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="mibandFlag ? 'active' : ''"
            @click="mibandFlag = !mibandFlag"
          >
            {{ mibandFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="mibandFlag ? 'display:block' : ''"
          v-html="$t('guide.miband-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os01"
            ><b>{{ $t('guide.google') }}</b
            >(SDK 1.7)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('android')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('android')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="googleFlag ? 'active' : ''"
            @click="googleFlag = !googleFlag"
          >
            {{ googleFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="googleFlag ? 'display:block' : ''"
          v-html="$t('guide.google-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os01"
            ><b>{{ $t('guide.samsung') }}</b
            >(SDK 1.7)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('android')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('android')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="samsungFlag ? 'active' : ''"
            @click="samsungFlag = !samsungFlag"
          >
            {{ samsungFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="samsungFlag ? 'display:block' : ''"
          v-html="$t('guide.samsung-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os02"
            ><b>{{ $t('guide.ios') }}</b
            >(SDK 1.0)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('ios')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('ios')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="iosFlag ? 'active' : ''"
            @click="iosFlag = !iosFlag"
          >
            {{ iosFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="iosFlag ? 'display:block' : ''"
          v-html="$t('guide.ios-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os03"
            ><b>{{ $t('guide.js') }}</b
            >(SDK 1.6)</span
          >
          <button class="bt-md bt-default" @click="downloadGuide('js')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('js')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="javascriptFlag ? 'active' : ''"
            @click="javascriptFlag = !javascriptFlag"
          >
            {{ javascriptFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="javascriptFlag ? 'display:block' : ''"
          v-html="$t('guide.js-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os03"
            ><b>{{ $t('guide.interface-doc') }}</b></span
          >
          <button class="bt-md bt-default" @click="downloadGuide('interface')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="interfaceFlag ? 'active' : ''"
            @click="interfaceFlag = !interfaceFlag"
          >
            {{ interfaceFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="interfaceFlag ? 'display:block' : ''"
          v-html="$t('guide.interface-view')"
        ></div>
        <h4 class="acc-head">
          <span class="tit os03"
            ><b>{{ $t('guide.push') }}</b></span
          >
          <button class="bt-md bt-default" @click="downloadGuide('push')">
            {{ $t('guide.btn-guide') }}
          </button>
          <button class="bt-md bt-default" @click="downloadSdk('push')">
            {{ $t('guide.btn-sdk') }}
          </button>
          <button
            class="bt-md bt-default accBtn"
            :class="pushFlag ? 'active' : ''"
            @click="pushFlag = !pushFlag"
          >
            {{ pushFlag ? $t('common.btn-close') : $t('guide.btn-view') }}
          </button>
        </h4>
        <div
          class="acc-body guide-content"
          :style="pushFlag ? 'display:block' : ''"
          v-html="$t('guide.push-view')"
        ></div>
      </div>
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
export default {
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.support'),
        this.$i18n.t('subMenu.guide'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.support'),
        smallTitle: this.$i18n.t('menu.support-eng'),
      },
      omronFlag: false,
      mibandFlag: false,
      googleFlag: false,
      samsungFlag: false,
      iosFlag: false,
      javascriptFlag: false,
      interfaceFlag: false,
      pushFlag: false,
    };
  },
  methods: {
    // 개발자가이드 다운로드
    downloadGuide(type) {
      let returnUrl = `${process.env.VUE_APP_URL_PORTAL}/download/guide/${type}`;
      if (type === 'interface') {
        returnUrl = `${process.env.VUE_APP_URL_PORTAL}/download/${type}`;
      }
      location.href = returnUrl;
    },
    // sdk 다운로드
    downloadSdk(type) {
      location.href = `${process.env.VUE_APP_URL_PORTAL}/download/sdk/${type}`;
    },
  },
};
</script>
<style lang=""></style>
